import React from 'react';
import styled, { css } from 'styled-components';

import { useAuth } from 'context/AuthProvider';
import IconLogOut from 'images/icons/LogOut';
import useEmailAuth from 'context/useEmailAuth';
import useSmsAuth from 'context/useSmsAuth';
import BlankButton from 'components/forms/BlankButton';

const Wrapper = styled.div`
	text-align: ${p => (p.centered && 'center') || 'left'};
	margin: ${p => p.theme.gutters.small} 0 0;
	${p =>
		p.theme.media.smallOnly(css`
			margin: ${p => p.theme.gutters.xsmall} 0 0;
		`)}
	${p =>
		p.textcolor === 'black' &&
		css`
			span,
			button,
			button:hover,
			svg {
				color: ${p => p.theme.colors.grey900};
				transition: opacity 350ms ease;
			}
			button:hover {
				opacity: 0.75;
			}
		`};

	${p =>
		p.textcolor === 'white' &&
		css`
			span,
			button,
			button:hover,
			svg {
				color: white;
				transition: opacity 350ms ease;
			}
			button:hover {
				opacity: 0.75;
			}
		`};
`;

const LogOutButton = styled(BlankButton)`
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	text-decoration: none;
	svg {
		fill: ${p => p.theme.colors.white};
		vertical-align: text-bottom !important;
		margin-left: 7.5px;
		width: 14px;
		height: 18px;
		transition: transform 350ms ease;
	}
	&:hover {
		svg {
			transform: translateX(3px);
		}
	}
`;

export default function AuthLogOut({
	text = 'Avslutt og logg ut',
	icon = true,
	onClick = () => {},
	...props
}) {
	const { isAuthenticated: isKeyCloakAuth, logout, method } = useAuth();
	const { isAuthenticated: isEmailAuth, logout: logoutEmail } =
		useEmailAuth();
	const { isAuthenticated: isSmsAuth, logout: logoutSms } = useSmsAuth();

	const isAuthenticated = isKeyCloakAuth || isEmailAuth || isSmsAuth;

	if (!isAuthenticated) return null;

	return (
		<Wrapper className="auth-logout" {...props}>
			<LogOutButton
				title={text || (method && `Avslutt og logg ut av ${method}`)}
				data-cy="auth-logout-button"
				onClick={async () => {
					onClick && onClick();

					if (!isAuthenticated) return;

					if (isSmsAuth) {
						return logoutSms({
							redirect: '/',
							entry: props?.entry,
						});
					}

					if (isEmailAuth) {
						return logoutEmail({
							redirect: '/',
						});
					}

					logout();
				}}>
				<span>
					{text ||
						(method && `Avslutt og logg ut av ${method}`) ||
						'Avslutt og logg ut'}
				</span>
				{icon && (
					<IconLogOut
						width="14"
						height="18"
						title={
							text ||
							(method && `Avslutt og logg ut av ${method}`) ||
							'Avslutt og logg ut'
						}
					/>
				)}
			</LogOutButton>
		</Wrapper>
	);
}
